import { useState } from 'react';
import dynamic from 'next/dynamic';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { AccordionBlockItemProps, AccordionBlockProps } from '../types';
import Image from './Image';
import { useTranslation } from '../lib/hooks.context';

const HtmlContent = dynamic(() => import('./HtmlContent'));

const AccordionBlock = ({
  data: accordion,
  accordionType,
}: AccordionBlockProps) => {
  const [hover, setHover] = useState(-1);
  const t = useTranslation();
  const isArticleVariant = accordionType === 'basic';

  return (
    <Accordion allowToggle variant="content" data-test-id="block-accordion">
      {accordion?.map(
        (
          { title, text, image: imageSrc, label }: AccordionBlockItemProps,
          i,
        ) => (
          <AccordionItem
            key={title}
            isFocusable
            data-test-id={`accordion-item-${i + 1}`}
          >
            {({ isExpanded }) => (
              <>
                <Heading variant={isArticleVariant ? 'h5' : 'h3'} as="h3">
                  <AccordionButton
                    cursor="pointer"
                    py={[5, 5, 5, 7]}
                    px={0}
                    onMouseOver={() => {
                      setHover(i);
                    }}
                    onMouseOut={() => {
                      setHover(-1);
                    }}
                  >
                    {title && (
                      <Flex flex="1" textAlign="left" alignItems="center">
                        <Text>{title}</Text>
                        {label && (
                          <Box
                            fontSize={['.8125rem', '.875rem']}
                            fontWeight="600"
                            color="white"
                            py={['.45rem', '.657rem']}
                            px={[2, 4]}
                            ml={5}
                            mr={2}
                            whiteSpace="nowrap"
                            borderRadius={5}
                            bg="greenBirch"
                          >
                            {label}
                          </Box>
                        )}
                      </Flex>
                    )}
                    <Button
                      as="div"
                      variant="outlineGreen"
                      {...(hover === i ? { 'data-hover': 'true' } : null)}
                    >
                      {isExpanded ? t('hide') : t('show')}
                    </Button>
                  </AccordionButton>
                </Heading>
                <AccordionPanel
                  pt={3}
                  pb={10}
                  px={0}
                  display="flex"
                  flexWrap="wrap"
                >
                  <SimpleGrid
                    columns={
                      !isArticleVariant
                        ? [1, null, null, imageSrc ? 2 : 1, 2]
                        : 1
                    }
                    spacing={5}
                    width="100%"
                  >
                    {imageSrc && (
                      <Box
                        flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 50%']}
                      >
                        <Image
                          width="900"
                          height="600"
                          src={imageSrc}
                          alt={title}
                        />
                      </Box>
                    )}
                    <Box
                      flex={['0 0 100%', '0 0 100%', '0 0 100%', '0 0 50%']}
                      sx={{ '> *:first-child': { marginTop: '0' } }}
                    >
                      <HtmlContent html={text} />
                    </Box>
                  </SimpleGrid>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        ),
      )}
    </Accordion>
  );
};

export default AccordionBlock;
